const rootPath = '/img/logo'

const logoVariants = {
  white252x80: `${rootPath}/my-omnia-white-252x80.png`,
  purple252x80: `${rootPath}/my-omnia-purple-252x80.png`,
  pure500x499: `${rootPath}/my-omnia-pure-500x499.png`,
  whiteNoText160x160: `${rootPath}/my-omnia-no-text-160x160.png`,
  purpleNoText112x112: `${rootPath}/my-omnia-no-text-0.5-112x112.png`,
}

export default logoVariants