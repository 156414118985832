import { mixpanelEvents } from '@/constants/mixpanel';
import { orgAdminRoutes } from '@/constants/routes/app';
import useSubmitOrganization from '@/hooks/onboarding/useSubmitOrganization';
import useToast from '@/hooks/useToast';
import RepresentativeForm from '@/modules/onboarding/organization/A_RepresentativeForm';
import LogoUpload from '@/modules/onboarding/organization/B_LogoUpload';
import RosterTemplate from '@/modules/onboarding/organization/C_RosterTemplate';
import RosterPreview from '@/modules/onboarding/organization/D_RosterPreview';
import useTelemetry from '@/providers/TelemetryProvider/useTelemetry';
import { useAppStore } from '@/store/useAppStore';
import { useOrganizationOnboardingStore } from '@/store/useOrganizationOnboardingStore';
import { createFileRoute, redirect, useNavigate } from '@tanstack/react-router';
import { useEffect } from 'react';

// 0 - Representative Form (Contact Info)
// 1 - Logo Upload
// 2 - Roster Template (Download / Upload CSV Template)
// 3 - Roster Preview (Preview of uploaded and parsed CSV roster) d

const OnboardingOrganization = () => {
  const toast = useToast();
  const navigate = useNavigate({ from: orgAdminRoutes.onboardingOrganization });
  const { trackEvent } = useTelemetry();

  const {
    userScores: scores,
    organization,
    setOrganization,
    onboardingStatuses: { isUserOnboardingCompleted },
  } = useAppStore();

  const {
    step,
    nextStep,
    prevStep,
    setRepresentativeForm,
    setLogoFile,
    setRosterFile,
    eulaAcceptance,
    logoFile,
    rosterParsed,
    representativeForm,
  } = useOrganizationOnboardingStore();

  const { isPending, mutate } = useSubmitOrganization((orgResponse) => {
    toast({
      title: `Organization ${orgResponse.name} has been successfully setup`,
      description: isUserOnboardingCompleted
        ? 'You can now start using the platform'
        : 'You will be redirected user onboarding flow',
      status: 'success',
      isClosable: true,
      duration: 5000,
    });

    setOrganization(orgResponse);

    trackEvent(mixpanelEvents.ORG_SIGN_UP_DONE);

    if (!isUserOnboardingCompleted) {
      navigate({
        to: '/onboarding/user',
        replace: true,
      });

      return;
    }

    if (!scores) {
      navigate({
        to: '/screener/$screenerId',
        params: { screenerId: 'wholeness-screener' },
        replace: true,
      });

      return;
    }

    navigate({
      to: '/organization/dashboard',
      replace: true,
    });
  });

  const isRepresentativeForm = step === 0;
  const isLogoUpload = step === 1;
  const isRosterTemplate = step === 2;

  const onboardingStoreUnsub = useOrganizationOnboardingStore.subscribe((state) => state.step);

  useEffect(() => {
    return () => {
      onboardingStoreUnsub();
    };
  }, [onboardingStoreUnsub]);

  useEffect(() => {
    // DEBUG: For some reason this useEffect is triggered twice
    if (isLogoUpload || isRosterTemplate) {
      toast({
        title: 'Organization onboarding flow',
        description: 'Continue where you left off',
        status: 'info',
        isClosable: true,
        id: `onboarding-${step}-${Math.random()}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goNext = () => {
    nextStep();
  };
  const goBack = () => {
    prevStep();
  };

  if (isRepresentativeForm) {
    return (
      <RepresentativeForm
        onSubmit={(formData, eulaAcceptance) => {
          setRepresentativeForm(formData, eulaAcceptance);
          goNext();
        }}
      />
    );
  }

  if (isLogoUpload) {
    return (
      <LogoUpload
        onContinue={(file, preview) => {
          setLogoFile(file, preview);
          goNext();
        }}
        onGoBack={(file, preview) => {
          setLogoFile(file, preview);
          goBack();
        }}
      />
    );
  }

  if (isRosterTemplate) {
    return (
      <RosterTemplate
        onContinue={(file, preview, roster) => {
          setRosterFile(file, preview, roster);
          goNext();
        }}
        onGoBack={(file, preview, roster) => {
          setRosterFile(file, preview, roster);
          goBack();
        }}
      />
    );
  }

  const onOrganizationSubmit = () => {
    if (representativeForm === null || logoFile === null || rosterParsed === null) {
      toast({
        title: 'Organization onboarding flow',
        description: 'Please complete all steps before submitting',
        status: 'error',
        isClosable: true,
        duration: 5000,
      });
      return;
    }

    mutate({
      organizationId: organization?._id ?? '',
      formData: representativeForm,
      eulaAcceptance,
      logoFile,
      roster: rosterParsed,
    });
  };

  return (
    <RosterPreview
      onGoBack={goBack}
      onSubmit={onOrganizationSubmit}
      isDisabledSubmit={isPending}
      isLoading={isPending}
    />
  );
};

export const Route = createFileRoute('/_authenticated/_onboarding/onboarding/organization')({
  component: OnboardingOrganization,
  beforeLoad: async ({ context: { auth } }) => {
    const isOrgAdmin = auth?.isAdmin;
    const {
      onboardingStatuses: { isOrganizationOnboardingCompleted, isUserContactInfoCompleted },
    } = useAppStore.getState();
    const shouldRedirectToUser =
      (isOrganizationOnboardingCompleted && isOrgAdmin && !isUserContactInfoCompleted) || !isOrgAdmin;

    if (shouldRedirectToUser) {
      throw redirect({
        to: '/onboarding/user',
      });
    }
  },
});
