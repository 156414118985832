import svgIcons from '@/assets/svg/icons';
import svgFiles from '@/assets/svg/svgFiles';
import CustomButton from '@/components/atoms/CustomButton';
import ModalContainer from '@/components/molecules/ModalContainer';
import LoadingPage from '@/components/templates/LoadingPage';
import { FORM_FIELD_WIDTH, MAX_CONTAINER_WIDTH } from '@/constants/dimensions';
import useMemberCareFromMyOrgById from '@/hooks/membercare/useMemberCareFromMyOrgById';
import colors from '@/theme/colors';
import { TUserContactMemberCareForm } from '@/types/User.types';
import { getAgeFromBirthDay } from '@/utils/stringUtils';
import { Center, HStack, Image, Input, Text, VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { createFileRoute } from '@tanstack/react-router';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useAppStore } from '@/store/useAppStore';
import useToast from '@/hooks/useToast';
import EmailJS from '@emailjs/browser';

const MemberCareProfile = () => {
  const { memberCareId } = Route.useParams();
  const { user } = useAppStore();
  const toast = useToast();

  const { data: memberCare, isLoading } = useMemberCareFromMyOrgById(memberCareId);

  const emailRef = useRef<HTMLAnchorElement>(null);
  const calendlyRef = useRef<HTMLAnchorElement>(null);

  const [sendEmailModalVisible, setSendEmailModalVisible] = useState(false);

  const [isSendingEmail, setIsSendingEmail] = useState(false);

  const { register, formState, reset, handleSubmit } = useForm({
    mode: 'onChange',
    resolver: yupResolver(
      yup.object().shape({
        emailSubject: yup.string().required('Subject is required.').min(5, 'Subject too short'),
        emailContent: yup.string().required('Content is required.').min(10, 'Content too short'),
      }),
    ),
  });

  const { isValid, errors } = formState;

  const [customEmailError, setCustomEmailError] = useState('');

  const onFormSubmit = async (formData: TUserContactMemberCareForm) => {
    setIsSendingEmail(true);
    try {
      const fromEmail = user?.email ?? user?.user_metadata.email ?? '';

      let memberProfileBaseUrl = '';

      const appEnv = import.meta.env.VITE_SENTRY_ENV;

      switch (appEnv) {
        case 'production':
          memberProfileBaseUrl = 'https://backoffice.myomnia.health';
          break;

        case 'staging':
          memberProfileBaseUrl = 'https://staging.backoffice.myomnia.health';
          break;

        case 'development':
        default:
          memberProfileBaseUrl = 'https://dev.backoffice.myomnia.health';
          break;
      }

      await EmailJS.send(
        import.meta.env.VITE_EMAIL_SERVICE_ID,
        import.meta.env.VITE_EMAIL_TEMPLATE_ID,
        {
          emailContent: formData.emailContent,
          emailSubject: formData.emailSubject,
          fromEmail,
          fromName: user?.user_metadata.firstName ?? user?.firstName,
          toEmail: memberCare?.email,
          memberProfileUrl: `${memberProfileBaseUrl}/member-care/members/${user?.user_id}`,
        },
        { publicKey: import.meta.env.VITE_SENDGRID_PUBLIC_KEY },
      );

      setSendEmailModalVisible(false);
      reset();
      setCustomEmailError('');

      toast({
        title: 'Email Sent',
        description: 'Member Support contacted successfully',
        status: 'success',
      });
    } catch (e: any) {
      setCustomEmailError(e.message ?? e.text ?? e.code ?? e);
    } finally {
      setIsSendingEmail(false);
    }
  };

  if (isLoading || !memberCare) return <LoadingPage />;

  return (
    <VStack width={MAX_CONTAINER_WIDTH}>
      {/* Actual page content */}
      <VStack width={'100%'} marginBottom={'50px'} alignItems={'center'} gap={'4px'}>
        <VStack
          borderWidth={'4px'}
          borderRadius={'full'}
          borderColor={'white'}
          shadow={'0px 6px 24px 0px rgba(14, 0, 98, 0.2)'}
          overflow={'hidden'}
        >
          <Image
            src={
              memberCare.user_metadata.profilePicture ?? memberCare.user_metadata.profile_photo ?? svgFiles.genericUser
            }
            alt={memberCare.firstName ?? memberCare.user_metadata.firstName}
            width={120}
            height={120}
            borderRadius={'full'}
            objectFit={'cover'}
            style={{ backgroundColor: 'white' }}
          />
        </VStack>
        <Text variant={'loraSmallTitle'} marginTop={'10px'}>
          {memberCare.firstName ?? memberCare.user_metadata.firstName}{' '}
          {memberCare.lastName ?? memberCare.user_metadata.lastName}
        </Text>
        <Text variant={'urbanistSemiBold'}>Member Support Specialist</Text>
        {memberCare.user_metadata.dateOfBirth && (
          <Text
            variant={'urbanistRegular'}
            color={'text.mediumGray'}
          >{`${getAgeFromBirthDay(memberCare.user_metadata.dateOfBirth)} years old ${memberCare.sex.toLowerCase()}`}</Text>
        )}

        {/* Action buttons */}
        <HStack gap={8} marginTop={'40px'}>
          <a ref={emailRef} href={`mailto:${memberCare.email}`}></a>
          <CustomButton
            label="Send Email"
            onClick={() => setSendEmailModalVisible(true)}
            iconBeforeLabelSvgPath={svgIcons.email}
            iconBeforeLabelSvgColor={colors.text.mediumBlue}
            labelColor={colors.text.mediumBlue}
            labelHoverColor={colors.extra.black}
            hoverBackgroundColor="extra.white"
            borderHoverColor="transparent"
          />
          <a
            ref={calendlyRef}
            target="_blank"
            href={memberCare.user_metadata?.calendly_link}
            style={{ display: 'none' }}
          >
            {memberCare.user_metadata?.calendly_link}
          </a>
          <CustomButton
            label="Schedule Call"
            backgroundColor="primary.500"
            labelColor="extra.white"
            iconBeforeLabelJSX={<Image src={svgFiles.calendar} />}
            onClick={() => calendlyRef.current?.click()}
          />
        </HStack>
        <VStack
          backgroundColor={'white'}
          padding={'40px'}
          marginTop={'40px'}
          borderRadius={'12px'}
          alignItems={'flex-start'}
          minWidth={'500px'}
          maxWidth={'690px'}
        >
          <Text variant={'loraSmallTitle'} textAlign={'start'}>
            Biography
          </Text>
          <Text
            variant={'urbanistMediumLarge'}
            lineHeight={'22px'}
            color={'text.mediumBlue'}
            marginTop={'10px'}
            whiteSpace={'pre-wrap'}
          >
            {memberCare.user_metadata.bio}
          </Text>
        </VStack>
      </VStack>

      <ModalContainer
        isOpen={sendEmailModalVisible}
        onClose={() => setSendEmailModalVisible(false)}
        isBlur={false}
        width={'420px'}
        height={'20vh'}
        backgroundColor={'background.lightBlue'}
      >
        <Text variant={'loraTitle'} textAlign={'center'} fontSize={'26px'} marginTop={'30px'} paddingX={'40px'}>
          Send {memberCare.firstName ?? memberCare.user_metadata.firstName} an Email
        </Text>
        <Text variant={'urbanistMediumLarge'} textAlign={'center'} color={'text.mediumGray'} marginTop={'20px'}>
          Enter email subject and email content below.
        </Text>
        <Center>
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <VStack marginY={'40px'} width={FORM_FIELD_WIDTH} gap={5}>
              <Input
                {...register('emailSubject', {
                  required: true,
                })}
                className="custom-input"
                placeholder={'Email Subject'}
                _placeholder={{
                  fontWeight: '600',
                  color: 'background.blueGray',
                }}
              />
              {errors.emailSubject && <Text variant={'error'}>{errors.emailSubject.message}</Text>}
              <Input
                {...register('emailContent', {
                  required: true,
                })}
                className="custom-input"
                placeholder={'Email Content'}
                _placeholder={{
                  fontWeight: '600',
                  color: 'background.blueGray',
                }}
              />
              {errors.emailContent && <Text variant={'error'}>{errors.emailContent.message}</Text>}
              {customEmailError && <Text variant={'error'}>{customEmailError}</Text>}
              <CustomButton
                style={{ marginTop: '20px' }}
                label="Send Email"
                backgroundColor="primary.500"
                width={FORM_FIELD_WIDTH}
                labelColor="white"
                disabled={!isValid}
                isLoading={isSendingEmail}
                buttonType={isValid ? 'submit' : 'button'}
              />
            </VStack>
          </form>
        </Center>
      </ModalContainer>
    </VStack>
  );
};

export const Route = createFileRoute('/_authenticated/_member-care/member-care/$memberCareId')({
  component: MemberCareProfile,
});
