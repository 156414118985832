import { useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { getOrganizationDashboard } from '@/utils/queryOptions';
import { Center, HStack, Image, Text, VStack } from '@chakra-ui/react';
import WellnessStateCircle from '@/components/molecules/WellnessStateCircle';
import OrgMembersRoster from '@/modules/organization/OrgMembersRoster';
import { EMPTY_WELLNESS_DOMAINS } from '@/constants';
import logoVariants from '@/assets/img/logo';
import { MAX_CONTAINER_WIDTH } from '@/constants/dimensions';
import CircleFillOutWithText from '@/components/molecules/WellnessStateCircle/CircleFillOutWithText';
import { formatDate } from 'date-fns';
import getCircleArrowTextDisplayFromTrend from '@/utils/getCircleArrowTextDisplayFromTrend';
import StatisticsItemWithCircleText from '@/components/molecules/StatisticsItemWithCircleText';
import DoughnutChart, { TChartColorItem, TChartDataItem } from '@/components/molecules/DoughnutChart';
import { useMemo } from 'react';
import {
  HIGH_CONCERN_LABEL,
  LOW_CONCERN_LABEL,
  NO_CHANGE_CONCERN_LABEL,
  SOME_CONCERN_LABEL,
} from '@/constants/stringVars';
import { generateConcernLevelChangeDisplay } from '@/utils/stringUtils';
import colors from '@/theme/colors';
import LoadingPage from '@/components/templates/LoadingPage';

const ORG_LOGO_SIZE = 240;

const CHART_COLORS: TChartColorItem[] = [
  { name: LOW_CONCERN_LABEL, value: colors.extra.teal },
  { name: SOME_CONCERN_LABEL, value: colors.extra.yellow },
  { name: HIGH_CONCERN_LABEL, value: colors.extra.red },
];

const OrganizationDashboard = () => {
  const { data: organizationDashboard } = useSuspenseQuery(getOrganizationDashboard());

  const concernLevelsChartData = useMemo(() => {
    const concernLevels = organizationDashboard?.concernLevels;
    const concernLevelsArray: TChartDataItem[] = [];

    concernLevelsArray.push({
      name: HIGH_CONCERN_LABEL,
      value: concernLevels?.highConcern.percentage ?? 0,
      description: !!concernLevels?.highConcern.change
        ? generateConcernLevelChangeDisplay(concernLevels.highConcern.change)
        : NO_CHANGE_CONCERN_LABEL,
    });
    concernLevelsArray.push({
      name: SOME_CONCERN_LABEL,
      value: concernLevels?.someConcern.percentage ?? 0,
      description: concernLevels?.someConcern.change
        ? generateConcernLevelChangeDisplay(concernLevels.someConcern.change)
        : NO_CHANGE_CONCERN_LABEL,
    });
    concernLevelsArray.push({
      name: LOW_CONCERN_LABEL,
      value: concernLevels?.lowConcern.percentage ?? 0,
      description: concernLevels?.lowConcern.change
        ? generateConcernLevelChangeDisplay(concernLevels.lowConcern.change)
        : NO_CHANGE_CONCERN_LABEL,
    });

    return concernLevelsArray;
  }, [organizationDashboard?.concernLevels]);

  const { totalScore, ...userWellnessScoresNoTotal } = organizationDashboard
    ? organizationDashboard.score
    : { totalScore: 0, ...EMPTY_WELLNESS_DOMAINS };

  return (
    <VStack width={MAX_CONTAINER_WIDTH} paddingBottom={'100px'} gap={0}>
      {/* Actual page content */}
      <VStack width={'100%'} marginBottom={'50px'}>
        {organizationDashboard?.logoUrl ? (
          <Image
            alt="user-avatar-wellness"
            width={`${ORG_LOGO_SIZE}px`}
            height={`${ORG_LOGO_SIZE}px`}
            src={organizationDashboard?.logoUrl}
            style={{ borderRadius: '50%', objectFit: 'cover' }}
          />
        ) : (
          <Center
            borderRadius={'50%'}
            border={'5px solid white'}
            boxShadow={'0px 4px 8px 0px #0E00621A'}
            width={`${ORG_LOGO_SIZE}px`}
            height={`${ORG_LOGO_SIZE}px`}
            backgroundColor={'primary.100'}
          >
            <Image src={logoVariants.purpleNoText112x112} width={'30px'} height={'30px'} alt="my-omnia-logo" />
          </Center>
        )}
        <Text variant={'urbanistTitleMedium'} marginTop={'14px'}>
          {organizationDashboard.name}
        </Text>

        {/* TODO: space-between kad se vrati 'Peer Support' dugme */}
        {/* <HStack width={'100%'} justifyContent={'center'} marginTop={'70px'}>
            <Text variant={'urbanistSemiBoldTitle'} fontWeight={700}>
              Organization Wholeness Report
            </Text>
            <CustomButton
              backgroundColor="secondary.500"
              labelColor="extra.white"
              label="Peer Support"
              width={'170px'}
              onClick={() => {}}
            />
          </HStack> */}

        {/* Concern Levels and Engagement */}
        <HStack gap={'50px'} marginTop={'80px'} justifyContent={'space-between'} width={'100%'}>
          {/* Concern Levels */}
          <VStack>
            <DoughnutChart
              data={concernLevelsChartData}
              colors={CHART_COLORS}
              chartSvgWidth={260}
              titleElement={
                <Text variant={'loraMediumTitle'} marginBottom={'40px'}>
                  Concern Levels
                </Text>
              }
              centeredElement={
                <CircleFillOutWithText
                  percentageFilled={organizationDashboard.score.totalScore * 10}
                  thickness={7}
                  svgId="doughnut-center-element"
                  percentageTextStyle={{ fontSize: 18, fontWeight: 800 }}
                  circleDiameter={90}
                />
              }
              // TODO: will maybe need later
              // footerElement={
              //   <VStack marginY={'10px'} gap={'10px'}>
              //     <Text variant={'urbanistRegular'} fontWeight={600} color="text.mediumGray">
              //       Up 6% since last quarter
              //     </Text>
              //     <Text variant={'urbanistRegular16'} textAlign={'center'}>
              //       Check out the
              //       <span style={{ color: colors.primary[500], fontWeight: 700 }}> resources section </span> below for
              //       tips and ideas on how to respond to these concerns.
              //     </Text>
              //   </VStack>
              // }
            />
          </VStack>

          {/* Engagement */}
          <VStack height={'100%'}>
            <Text variant={'loraMediumTitle'} marginBottom={'40px'}>
              Engagement
            </Text>
            <VStack
              backgroundColor={'extra.white'}
              boxShadow="0px 0px 20px 0px #00417926"
              width={'530px'}
              borderRadius={'12px'}
              paddingX={'30px'}
              paddingY={'20px'}
              gap={'20px'}
            >
              <StatisticsItemWithCircleText
                textTitle="# of Users Signed Up vs Invited"
                textSubtitle="Total"
                circlePercentageFilled={
                  (organizationDashboard.engagement.signedUp / organizationDashboard.engagement.invited) * 100
                }
                circleTitle={organizationDashboard.engagement.signedUp}
                circleSubtitle={
                  <Text variant={'urbanistSmallBold'} color={'text.mediumGray'}>
                    /{organizationDashboard.engagement.invited}
                  </Text>
                }
              />
              <StatisticsItemWithCircleText
                textTitle="# of Users who Took the Screener"
                textSubtitle="Past 30 days"
                circlePercentageFilled={
                  (organizationDashboard.engagement.screenerParticipants.last30Days /
                    organizationDashboard.engagement.signedUp) *
                  100
                }
                circleTitle={organizationDashboard.engagement.screenerParticipants.last30Days}
                circleSubtitle={
                  <Text variant={'urbanistSmallBold'} color={'text.mediumGray'}>
                    /{organizationDashboard.engagement.signedUp}
                  </Text>
                }
              />
              <StatisticsItemWithCircleText
                circlePercentageFilled={
                  (organizationDashboard.engagement.screenersTaken.last30Days /
                    organizationDashboard.engagement.signedUp) *
                  100
                }
                circleTitle={organizationDashboard.engagement.screenersTaken.last30Days}
                circleSubtitle={getCircleArrowTextDisplayFromTrend(
                  organizationDashboard.engagement.screenersTaken.trend,
                )}
                textTitle="# of Screeners Taken"
                textSubtitle="Past 30 days"
              />
            </VStack>
          </VStack>
        </HStack>

        <Text marginY={'40px'} variant={'loraMediumTitle'}>
          Breakdown of your team’s strengths and opportunities per category
        </Text>
        <WellnessStateCircle
          wellnessData={userWellnessScoresNoTotal}
          totalScore={totalScore}
          userAvatar={organizationDashboard?.logoUrl}
          showCenteredTextOverlay={false}
          avatarTooltipJSX={
            <>
              <Text color={'text.darkBlue'} variant={'urbanistRegular'} fontWeight={600} width={'100%'}>
                Your Team's Progress
              </Text>
              <HStack gap={'20px'}>
                {organizationDashboard.progress.map((progressMonthData) => (
                  <VStack key={progressMonthData.month} paddingTop={'10px'}>
                    <CircleFillOutWithText
                      showPercentageText={false}
                      percentageFilled={progressMonthData.averageScore * 10}
                      svgId={`team-hover-element-${progressMonthData.month}`}
                      centeredElement={
                        <Text fontSize={'12px'} fontWeight={800}>
                          {progressMonthData.screenerCount}
                        </Text>
                      }
                    />
                    <Text variant={'urbanistSmallBold'} textAlign={'center'}>
                      {formatDate(new Date(progressMonthData.year, progressMonthData.month - 1, 1), 'MMM')}
                    </Text>
                  </VStack>
                ))}
              </HStack>
            </>
          }
        />

        <OrgMembersRoster />
      </VStack>
    </VStack>
  );
};

export const Route = createFileRoute('/_authenticated/_organization/organization/dashboard')({
  component: OrganizationDashboard,
  loader: async ({ context }) => context.queryClient.ensureQueryData(getOrganizationDashboard()),
  // NOTE: Default pending component can be overridden if you want to show a custom pending states. This is just a showcase
  pendingComponent: () => <LoadingPage />,
});
