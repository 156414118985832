import svgIcons from '@/assets/svg/icons';
import SvgIcon from '@/components/atoms/SvgIcon';
import colors from '@/theme/colors';
import { TTimelineType } from '@/types/Timeline.types';

const getTimelineIconFromType = (timelineType: TTimelineType) => {
  switch (timelineType) {
    case 'assessment':
    case 'screener':
      return (
        <SvgIcon iconPath={svgIcons.assessment} color={colors.text.mediumBlue} size={25} boxWidth={18} boxHeight={17} />
      );

    case 'memberCareNote':
    case 'selfNote':
    default:
      return <SvgIcon iconPath={svgIcons.note} color={colors.text.mediumBlue} size={22} boxWidth={14} boxHeight={18} />;
  }

  // return <SvgIcon iconPath={svgIcons.phone} color={colors.text.mediumBlue} size={25} boxWidth={18} boxHeight={17} />;
  // return <Image alt="date-input-calendar" src={svgIcons.calendar} height={'16px'} width={'16px'} />;
};

export default getTimelineIconFromType;
