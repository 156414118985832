import React from 'react';
import ModalContainer from '../ModalContainer';
import { HStack, Text, VStack } from '@chakra-ui/react';
import CustomButton from '@/components/atoms/CustomButton';
import colors from '@/theme/colors';

interface Props {
  title: string;
  description: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  confirmButtonText: string;
  confirmButtonColor?: string;
  confirmLoading?: boolean;
}

const ConfirmationModal = ({
  title,
  description,
  isOpen,
  onClose,
  onConfirm,
  confirmButtonText,
  confirmButtonColor = 'primary.500',
  confirmLoading,
}: Props) => {
  return (
    <ModalContainer
      isBlur={false}
      isOpen={isOpen}
      onClose={onClose}
      width={'40%'}
      height={'20vh'}
      backgroundColor="white"
      footerContent={
        <HStack justifyContent={'center'} width={'100%'} marginBottom={'20px'} gap={'30px'}>
          <CustomButton
            isTransparent
            labelColor="text.mediumBlue"
            labelHoverColor={colors.extra.black}
            borderHoverColor="transparent"
            label="Cancel"
            onClick={onClose}
            labelStyle={{ fontSize: '16px' }}
            style={{ marginTop: '20px' }}
            height={'60px'}
            width={'100px'}
          />
          <CustomButton
            label={confirmButtonText}
            labelColor="extra.white"
            labelHoverColor="extra.white"
            backgroundColor={confirmButtonColor}
            labelStyle={{ fontSize: '16px' }}
            onClick={onConfirm}
            style={{ marginTop: '20px', paddingInline: '20px', minWidth: '100px' }}
            height={'60px'}
            isLoading={confirmLoading}
          />
        </HStack>
      }
    >
      <VStack marginTop={'30px'}>
        <Text variant={'loraSmallTitle'} color={'black'}>
          {title}
        </Text>
        <Text fontSize={'20px'} marginTop={'20px'} textAlign={'center'} paddingX={'20px'}>
          {description}
        </Text>
      </VStack>
    </ModalContainer>
  );
};

export default ConfirmationModal;
