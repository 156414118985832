import React, { useCallback, useEffect, useState } from 'react';
import { parse as parseCSV } from 'papaparse';
import onboardingMessages from '@/messages/onboarding';
import OrgOnboardingPage from './OrgOnboardingPage';
import { Box, Button, Center, HStack, Image, Input, Text, VStack } from '@chakra-ui/react';
import downloadFile from '@/utils/downloadFile';
import FilePicker from '@/components/atoms/FilePicker';
import { TRosterItemPerson, TRosterTabSelection } from '@/types/Roster.types';
import iconsPng from '@/assets/img/png/icons';
import { useOrganizationOnboardingStore } from '@/store/useOrganizationOnboardingStore';
import { MAX_CHARS_IN_PHONE, MAX_LINES_IN_ROSTER, MIN_CHARS_IN_PHONE } from '@/constants';
import { BUTTON_HEIGHT, FORM_FIELD_WIDTH } from '@/constants/dimensions';
import TabButtons from '@/components/atoms/TabButtons';
import { useFieldArray, useForm } from 'react-hook-form';
import CustomButton from '@/components/atoms/CustomButton';
import svgIcons from '@/assets/svg/icons';
import colors from '@/theme/colors';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { EMAIL_REGEX } from '@/constants/stringVars';

type Props = {
  onGoBack?: (file: File | null, preview: string | null, roster: TRosterItemPerson[] | null) => void;
  onContinue?: (file: File | null, preview: string | null, roster: TRosterItemPerson[] | null) => void;
};

const PreviewSection = ({
  title,
  description,
  number,
  buttonLabel,
  onButtonPress,
  isButtonDisabled = false,
}: {
  title: string;
  description: string;
  number: string;
  buttonLabel: string;
  onButtonPress: () => void;
  isButtonDisabled?: boolean;
}) => {
  return (
    <VStack>
      <HStack alignItems={'flex-start'} gap={0}>
        <Center background={'secondary.500'} borderRadius={'full'} width={'30px'} height={'30px'}>
          <Text fontWeight={'bold'} color={'white'} lineHeight={'20px'} fontSize={'16px'}>
            {number}
          </Text>
        </Center>
        <VStack paddingX={'16px'}>
          <Text fontWeight={600} fontSize={'26px'} lineHeight={'32px'}>
            {title}
          </Text>
          <Text
            fontSize={'16px'}
            maxWidth={'400px'}
            textAlign={'center'}
            lineHeight={'20px'}
            marginTop={'16px'}
            color={'text.mediumBlue'}
            fontWeight={400}
          >
            {description}
          </Text>
        </VStack>
      </HStack>
      <Button
        marginTop={'20px'}
        variant={'formSubmit'}
        onClick={onButtonPress}
        disabled={isButtonDisabled}
        isDisabled={isButtonDisabled}
        backgroundColor={'secondary.500'}
        _hover={{ backgroundColor: isButtonDisabled ? 'secondary.400' : 'secondary.600' }}
      >
        {buttonLabel}
      </Button>
    </VStack>
  );
};
type TManualRosterUserType = Omit<TRosterItemPerson, 'Invitation'>;

const MANUAL_ROSTER_ITEM_FIELDS = ['First Name', 'Last Name', 'Email', 'Phone'] as (keyof TManualRosterUserType)[];
const MANUAL_ROSTER_ITEM_DEFAULT_VALUES: TManualRosterUserType = {
  'First Name': '',
  'Last Name': '',
  Email: '',
  Phone: '',
};

const schema = yup
  .object()
  .shape({
    manualRosterUsers: yup
      .array()
      .of(
        yup.object().shape({
          'First Name': yup.string().required('First Name is required.'),
          'Last Name': yup.string().required('Last Name is required.'),
          Email: yup.string().required('Email is required.').matches(EMAIL_REGEX, 'Wrong email format.'),
          Phone: yup
            .string()
            .required('Phone is required')
            .min(MIN_CHARS_IN_PHONE, `Minimum ${MIN_CHARS_IN_PHONE} digits required`)
            .max(MAX_CHARS_IN_PHONE, `Maximum ${MAX_CHARS_IN_PHONE} digits allowed`),
        }),
      )
      .min(1, 'At least 1 member is required'),
  })
  .required();

const RosterTemplate = ({ onGoBack = () => {}, onContinue = () => {} }: Props) => {
  const csvFileRef = React.useRef<HTMLInputElement>(null);

  const { rosterFilePreview, rosterParsed, selectedRosterTab, setSelectedRosterTab } = useOrganizationOnboardingStore();

  const [currentRosterTabSelection, setCurrentRosterTabSelection] = useState<TRosterTabSelection>(
    selectedRosterTab ?? 'manual',
  );

  // preview - file name
  const [preview, setPreview] = useState<string | null>(rosterFilePreview ?? null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const [roster, setRoster] = useState<TRosterItemPerson[] | null>(selectedRosterTab === 'csv' ? rosterParsed : null);

  const { control, register, formState, handleSubmit } = useForm<{ manualRosterUsers?: TManualRosterUserType[] }>({
    defaultValues: {
      manualRosterUsers:
        selectedRosterTab === 'manual' && rosterParsed ? rosterParsed : [MANUAL_ROSTER_ITEM_DEFAULT_VALUES],
    },
    mode: 'all',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const { errors, isValid } = formState;

  const {
    fields: manualRosterUsersFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'manualRosterUsers',
  });

  const [customRosterError, setCustomRosterError] = useState('');

  useEffect(() => {
    if (selectedFile) {
      parseCSV(selectedFile, {
        header: true,
        skipEmptyLines: true,
        complete: (results: { data: TRosterItemPerson[] }) => {
          if (results.data.length > MAX_LINES_IN_ROSTER) {
            setCustomRosterError(onboardingMessages.tooManyRecordsInRosterFile);
          } else {
            setCustomRosterError('');
            setRoster(results.data as TRosterItemPerson[]);
            setPreview(selectedFile?.name);
          }
        },
      });
    }
  }, [selectedFile]);

  const triggerFilePicker = () => {
    if (csvFileRef.current) {
      csvFileRef.current.click();
    }
  };

  const onSubmitRoster = useCallback(() => {
    if (currentRosterTabSelection === 'manual') {
      handleSubmit(({ manualRosterUsers }) => {
        onContinue(
          null,
          null,
          manualRosterUsers?.map((user) => ({ ...user, Invitation: '' })) ?? [], // need to artificially add Invitation
        );
      })();
    } else {
      onContinue(selectedFile, preview, roster);
    }

    setSelectedRosterTab(currentRosterTabSelection);
  }, [currentRosterTabSelection, manualRosterUsersFields, selectedFile, preview, roster]);

  return (
    <OrgOnboardingPage
      title={onboardingMessages.createYourRosterTitle}
      subTitle={onboardingMessages.createYourRosterSubtitle}
      description={
        currentRosterTabSelection === 'manual'
          ? onboardingMessages.createYourRosterDescriptionManual
          : onboardingMessages.createYourRosterDescriptionCSV
      }
      onGoBack={() => {
        onGoBack(selectedFile, preview, roster);
      }}
      onSubmit={onSubmitRoster}
      isSubmitDisabled={
        currentRosterTabSelection === 'manual' ? manualRosterUsersFields.length < 1 || !isValid : !preview
      }
    >
      <VStack marginTop={'40px'}>
        <TabButtons
          options={[
            {
              labelDisplay: 'MANUALLY ADD',
              labelValue: 'manual',
              onClick: () => setCurrentRosterTabSelection('manual'),
            },
            { labelDisplay: 'UPLOAD CSV', labelValue: 'csv', onClick: () => setCurrentRosterTabSelection('csv') },
          ]}
          selectedOptionValue={currentRosterTabSelection}
        />
      </VStack>

      {currentRosterTabSelection === 'manual' ? (
        <VStack spacing={'15px'} marginTop={'40px'}>
          {manualRosterUsersFields?.map((field, index) => (
            <HStack key={field.id} alignItems={'flex-start'}>
              {MANUAL_ROSTER_ITEM_FIELDS.map((itemFieldName) => (
                <VStack key={itemFieldName} gap={'4px'}>
                  <Input
                    {...register(`manualRosterUsers.${index}.${itemFieldName}`, { required: true })}
                    className="custom-input"
                    placeholder={itemFieldName}
                    _placeholder={{ fontWeight: '600', color: 'text.mediumGray' }}
                    borderColor={errors?.manualRosterUsers?.[index]?.[itemFieldName] ? 'extra.red' : undefined}
                  />
                  <Text variant={'errorSmall'} height={'10px'} width={'100%'} paddingLeft={'18px'}>
                    {errors?.manualRosterUsers?.[index]?.[itemFieldName] &&
                      errors?.manualRosterUsers?.[index]?.[itemFieldName].message}
                  </Text>
                </VStack>
              ))}

              <VStack position={'relative'} data-group>
                <VStack position={'absolute'} left={'10px'} height={BUTTON_HEIGHT} justifyContent={'center'}>
                  {/* <VStack>
                    <SvgIcon iconPath={svgIcons.closeSmall} color={colors.extra.red} size={15}  />
                  </VStack> */}
                  <CustomButton
                    onClick={() => remove(index)}
                    iconBeforeLabelSvgSize={15}
                    iconBeforeLabelSvgPath={svgIcons.closeSmall}
                    iconBeforeLabelSvgColor={colors.extra.red}
                    width={'30px'}
                    height={'30px'}
                    disabledColor="transparent"
                    labelStyle={{ marginLeft: 0, marginTop: '6px' }}
                    style={{ minWidth: '20px', borderRadius: '50%', paddingInline: 0 }}
                    disabled={manualRosterUsersFields.length === 1}
                  />
                  {manualRosterUsersFields.length === 1 && (
                    <Text
                      _groupHover={{ visibility: 'visible' }}
                      visibility={'hidden'}
                      variant={'urbanistSemiBoldRegular'}
                      position={'absolute'}
                      width={'135px'}
                      left={'55px'}
                      top={'15px'}
                      padding={'10px'}
                      borderRadius={'8px'}
                      backgroundColor={'extra.white'}
                      boxShadow={'0px 6.97px 34.86px 0px #00417930'}
                    >
                      You must have at least 1 member.
                    </Text>
                  )}
                </VStack>
              </VStack>
            </HStack>
          ))}

          {/* Add new member button */}
          {manualRosterUsersFields.length < MAX_LINES_IN_ROSTER ? (
            <CustomButton
              isTransparent
              onClick={() => append(MANUAL_ROSTER_ITEM_DEFAULT_VALUES)}
              style={{
                width: '200px',
                height: '55px',
              }}
              label={manualRosterUsersFields.length === 0 ? 'Add at least One Member' : 'Add Another Member'}
              labelHoverColor={colors.text.darkBlue}
              iconBeforeLabelSvgPath={svgIcons.plus}
              borderHoverColor="transparent"
            />
          ) : (
            <Text>You've reached the maximum number of users in your Roster.</Text>
          )}
        </VStack>
      ) : (
        <VStack spacing={'50px'} marginTop={'40px'}>
          <VStack marginTop={'10px'}>
            <Text fontSize={'20px'} color={'text.darkBlue'} lineHeight={'24px'} fontWeight={'600'}>
              {onboardingMessages.createYourRosterCSVSubtitle}
            </Text>
            <Text color={'text.mediumGray'} lineHeight={'20px'} fontWeight={'500'}>
              {onboardingMessages.createYourRosterCSVSubtitleDescription}
            </Text>
          </VStack>
          <PreviewSection
            title={onboardingMessages.downloadOurRosterTemplate}
            description={onboardingMessages.downloadRosterTemplateDescription}
            number={'1'}
            buttonLabel={onboardingMessages.downloadRosterTemplate}
            onButtonPress={() => {
              downloadFile('/files/roster-template.csv');
            }}
          />
          <VStack>
            <PreviewSection
              title={onboardingMessages.uploadYourCompletedRoster}
              description={onboardingMessages.uploadYourRosterDescription}
              number={'2'}
              buttonLabel={onboardingMessages.uploadCSV}
              onButtonPress={triggerFilePicker}
              isButtonDisabled={preview !== null && preview !== ''}
            />

            {preview && (
              <HStack
                width={'295px'}
                marginTop={'20px'}
                borderRadius={'8px'}
                background={'white'}
                padding={'18px'}
                justify={'space-between'}
              >
                <Text color={'text.darkBlue'} fontSize={'14px'} fontWeight={'500'} noOfLines={1} marginRight={'18px'}>
                  {preview}
                </Text>
                <Box
                  onClick={() => {
                    setSelectedFile(null);
                    if (csvFileRef.current) csvFileRef.current.value = '';
                    setPreview(null);
                  }}
                  cursor={'pointer'}
                >
                  <Image alt="delete-csv-x" src={iconsPng.redX} width={25} height={25} />
                </Box>
              </HStack>
            )}
          </VStack>

          <FilePicker
            inputRef={csvFileRef}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            setPreview={setPreview}
            accept={'.csv'}
          />
        </VStack>
      )}

      {customRosterError.length > 0 && (
        <Text variant={'error'} marginTop={'10px'} width={FORM_FIELD_WIDTH}>
          {customRosterError}
        </Text>
      )}
    </OrgOnboardingPage>
  );
};

export default RosterTemplate;
