const queryKeys = {
  auth: { user: 'user-auth' },
  organization: {
    members: 'org-members',
    dashboard: 'org-dashboard',
  },
  legal: {
    privacyPolicy: 'legal-privacy-policy',
    termsOfUse: 'legal-terms-of-use',
  },
  documents: {
    screener: 'screener-documents',
  },
  memberCare: {
    myOrganizationMemberCares: 'member-care-my-organization-member-cares',
    memberCareById: 'member-care-by-id',
  },
  notes: {
    self: 'notes-self',
  },
  timeline: {
    self: 'timeline-self',
  },
};

export default queryKeys;
