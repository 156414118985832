import { THealthDomainScores } from './types/Health.types.ts';

export const BACKOFFICE_SIGN_IN_URL = 'https://backoffice.myomnia.health/sign-in';

export const EMPTY_WELLNESS_DOMAINS: THealthDomainScores = {
  mental: 0,
  physical: 0,
  social: 0,
  spiritual: 0,
  vocational: 0,
  purpose: 0,
  fun: 0,
};

export const WELLNESS_DOMAIN_QUESTION_TYPES = ['wellness_level', 'readiness_level'] as const;

export const NO_OF_WELLNESS_DOMAINS = Object.values(EMPTY_WELLNESS_DOMAINS).length;

export const WELLNESS_DATA_MULTIPLIER = 10;

export const WELLNESS_STATE_LIMIT_DANGER = 33;
export const WELLNESS_STATE_LIMIT_OK = 66;

export const HOUR_IN_MILISECONDS = 3600000;

export const MAX_LINES_IN_ROSTER = 50;

export const REFETCH_MILISECONDS = 1000;

export const MEMBER_NOTE_TYPES = ['PERSONAL', 'MEMBERCARE_TO_USER', 'USER_TO_MEMBERCARE'] as const;
export const TIMELINE_TYPES = ['assessment', 'screener', 'memberCareNote', 'selfNote', 'observation'] as const;

export const MIN_CHARS_IN_NOTE_CONTENT = 10;
export const MAX_CHARS_IN_NOTE_CONTENT = 1000;

export const MIN_CHARS_IN_PHONE = 8;
export const MAX_CHARS_IN_PHONE = 18;
