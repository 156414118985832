import { useMemo } from 'react';

import svgIcons from '@/assets/svg/icons';
import CustomButton from '@/components/atoms/CustomButton';
import Loader from '@/components/atoms/Loader';
import WellnessStateCircle from '@/components/molecules/WellnessStateCircle';
import { EMPTY_WELLNESS_DOMAINS } from '@/constants';
import { MAX_CONTAINER_WIDTH } from '@/constants/dimensions';
import { employeeUserRoutes } from '@/constants/routes/app';
// import UserWellnessGoal from '@/modules/user/UserWellnessGoal'; // TODO: removed for now; will probably be added back later
import { useAppStore } from '@/store/useAppStore';
import colors from '@/theme/colors';
import { getAssessmentsResultLastScore } from '@/utils/queryOptions';
import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import UserMemberCares from '@/modules/user/UserMemberCares';
import UserNotes from '@/modules/user/UserNotes';
import UserWellnessTimeline from '@/modules/user/UserWellnessTimeline';
import { useOwnTimeline } from '@/hooks/timeline';

const UserDashboard = () => {
  const navigate = useNavigate({ from: employeeUserRoutes.userDashboard });

  const { user: userData } = useAppStore();

  const { data: userWellnessScores } = useSuspenseQuery(getAssessmentsResultLastScore());
  const {
    data: timelineItems,
    isLoading: isLoadingTimelineItems,
    refetch: refetchTimelineItems,
    isRefetching: isRefetchingTimelineItems,
  } = useOwnTimeline();

  const isLoading = !userData || userWellnessScores === EMPTY_WELLNESS_DOMAINS;

  const userWellnessScoresNoTotal = useMemo(() => {
    const totalRemoved = { ...userWellnessScores };
    delete totalRemoved.totalScore;
    return totalRemoved;
  }, [userWellnessScores]);

  // TODO: removed for now; will probably be added back later
  // const wellnessDataSorted = useMemo(() => {
  //   const wellnessDataArray = userWellnessScoresNoTotal
  //     ? Object.entries(userWellnessScoresNoTotal).map(([wellnessDomain, wellnessValue]) => ({
  //         domainName: capitalize(wellnessDomain),
  //         value: wellnessValue,
  //       }))
  //     : [];

  //   wellnessDataArray.sort((a, b) => (a.value > b.value ? 1 : -1));
  //   return wellnessDataArray;
  // }, [userWellnessScoresNoTotal]);

  // TODO: removed for now; will probably be added back later
  // const calculatedWellnessGoalData = useMemo(() => {
  //   return [
  //     {
  //       name: wellnessDataSorted[0].domainName,
  //       value: wellnessDataSorted[0].value > 0 ? 100 / wellnessDataSorted[0].value : 100,
  //     },
  //     {
  //       name: wellnessDataSorted[1].domainName,
  //       value: wellnessDataSorted[1].value > 0 ? 100 / wellnessDataSorted[1].value : 100,
  //     },
  //     {
  //       name: wellnessDataSorted[2].domainName,
  //       value: wellnessDataSorted[2].value > 0 ? 100 / wellnessDataSorted[2].value : 100,
  //     },
  //   ];
  // }, [wellnessDataSorted]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <VStack width={MAX_CONTAINER_WIDTH}>
      {/* Actual page content */}
      <VStack width={'100%'} marginBottom={'50px'} gap={'80px'}>
        <HStack justify={'space-between'} width={'100%'} marginBottom={'50px'}>
          <Text variant="loraTitle" textAlign={'start'}>
            Your Strengths & Opportunities for Growth
          </Text>
          <HStack gap={8}>
            <CustomButton
              onClick={() => navigate({ to: '/screener/$screenerId', params: { screenerId: 'wholeness-screener' } })}
              isTransparent
              label="Retake Screener"
              iconBeforeLabelSvgPath={svgIcons.assessment}
              iconBeforeLabelSvgColor={colors.text.darkBlue}
              labelColor={colors.text.darkBlue}
              hoverBackgroundColor="extra.white"
              borderHoverColor="transparent"
            />
            <CustomButton
              backgroundColor="primary.500"
              labelColor="extra.white"
              onClick={() => alert('Requesting . . .')}
              label="Request Peer Support"
              disabled
            />
          </HStack>
        </HStack>

        <VStack marginTop={'-50px'}>
          <WellnessStateCircle
            wellnessData={userWellnessScoresNoTotal}
            totalScore={userWellnessScores?.totalScore}
            userAvatar={userData?.user_metadata.profilePicture}
            showCenteredTextOverlay
          />
          <Text variant={'urbanistSmall'} color={'text.mediumGray'}>
            These results are not to be interpreted as a Diagnosis, but are meant to guide you in your wellness
            decisions
          </Text>
        </VStack>

        {/* Call emergency */}
        <Box
          backgroundColor={'background.cloudGray'}
          marginY={'40px'}
          paddingY={'40px'}
          paddingX={'80px'}
          borderRadius={'12px'}
          fontSize={'16px'}
          lineHeight={'20px'}
          fontWeight={500}
          maxWidth={'600px'}
        >
          <Text display={'inline'} variant={'lora'} fontStyle={'italic'}>
            If you feel like you are having a medical emergency{' '}
          </Text>
          <Text display={'inline'} fontWeight={800} color={'primary.500'}>
            dial 911
          </Text>
          <VStack display={'inline-block'} marginTop={'20px'} textAlign={'center'}>
            <Text display={'inline'} variant={'lora'} fontSize={'16px'} lineHeight={'20px'} fontStyle={'italic'}>
              If you are considering suicide, or struggling with your mental health and are having a crisis{' '}
            </Text>
            <Text display={'inline'} fontWeight={800} color={'primary.500'}>
              dial 988
            </Text>
          </VStack>
        </Box>

        {/* Wellness Goal and Notes */}
        <HStack width={'100%'} justifyContent={'center'} gap={'40px'} alignItems={'center'}>
          {/* TODO: removed for now; will probably be added back later */}
          {/* <UserWellnessGoal data={calculatedWellnessGoalData} /> */}
          <UserNotes memberId={userData.user_id} onNotesUpdate={refetchTimelineItems} />
        </HStack>

        {/* Wellness Timeline */}
        <UserWellnessTimeline
          isLoading={isLoadingTimelineItems || isRefetchingTimelineItems}
          timelineItems={timelineItems}
        />

        {/* Care Specialists (ex Bobbi section) */}
        <VStack marginTop={'40px'}>
          <Text variant={'loraTitle'} color={'text.darkBlue'} marginBottom={'50px'}>
            Ready to speak with someone for support?
          </Text>
          <UserMemberCares />
        </VStack>
      </VStack>

      {/* <Text variant="loraTitle">Error fetching wellness data for: {userData?.email}</Text> */}

      {/* TODO: removing for now */}
      {/* <OrganizationFeed /> */}
    </VStack>
  );
};

export const Route = createFileRoute('/_authenticated/_user/user/dashboard')({
  component: UserDashboard,
  loader: async ({ context }) => context.queryClient.ensureQueryData(getAssessmentsResultLastScore()),
});
