import { useMemo, useState } from 'react';

import { Center, Flex, Image, VStack } from '@chakra-ui/react';
import { THealthDomainScores } from '@/types/Health.types';
import DrawnCirclesBackground from '@/components/atoms/DrawnCirclesBackground';
import ColorOverlay from '@/components/atoms/ColorOverlay';
import { capitalize } from '@/utils/stringUtils';
import { NO_OF_WELLNESS_DOMAINS } from '@/constants';
import { OUTER_CIRCLE_SIZE } from '@/constants/dimensions';
import colors from '@/theme/colors';
import CircleFillOutWithText from './CircleFillOutWithText';
import WellnessStateCircleChunk from './WellnessStateCircleChunk';
import { getColorFromPercentage } from '@/utils/getColorFromPercentage';
import svgFiles from '@/assets/svg/svgFiles';

const AVATAR_SIZE = 180;
const CIRCLE_DIAMETER_PADDING = 20;

const ID_COMBINED_WELLNESS_SCORE = 'combined-wellness-score';

const WellnessStateCircle = ({
  wellnessData,
  userAvatar,
  totalScore,
  showCenteredTextOverlay,
  avatarTooltipJSX = null,
}: {
  wellnessData: THealthDomainScores;
  userAvatar?: string;
  totalScore?: number;
  showCenteredTextOverlay?: boolean;
  avatarTooltipJSX?: JSX.Element | null;
}) => {
  const totalScoreX10 = totalScore ? totalScore * 10 : null;

  const wellnessDataSorted = useMemo(() => {
    const wellnessDataArray = Object.entries(wellnessData).map(([wellnessDomain, wellnessValue]) => ({
      domainName: capitalize(wellnessDomain),
      value: wellnessValue,
    }));

    wellnessDataArray.sort((a, b) => (a.value > b.value ? 1 : -1));
    return wellnessDataArray;
  }, [wellnessData]);

  const [avatarTooltipPosition, setAvatarTooltipPosition] = useState<{ x: number; y: number } | null>(null);

  const combinedWellnessScore = Math.floor(
    Object.values(wellnessData).reduce((acc, next) => (acc += next), 0) / NO_OF_WELLNESS_DOMAINS,
  );

  return (
    <Center width={'100%'} position={'relative'} flexDir={'column'}>
      <DrawnCirclesBackground>
        <Center position={'absolute'} width={'100%'} height={'100%'}>
          <Flex height={AVATAR_SIZE} width={AVATAR_SIZE} borderRadius={'50%'} position={'relative'}>
            {showCenteredTextOverlay && (
              <ColorOverlay
                color1={colors.extra.black}
                color1Alpha={0.33}
                color2Alpha={0.33}
                style={{ borderRadius: '50%' }}
              />
            )}
            <Image
              alt="user-avatar-wellness"
              height={AVATAR_SIZE}
              width={AVATAR_SIZE}
              src={userAvatar && userAvatar !== '' ? userAvatar : svgFiles.genericUser}
              style={{ borderRadius: '50%', objectFit: 'cover' }}
            />
          </Flex>
          <Flex position={'absolute'} borderRadius={'50%'} zIndex={5}>
            {avatarTooltipPosition && (
              <VStack
                position={'absolute'}
                left={`${avatarTooltipPosition.x}px`}
                top={`${avatarTooltipPosition.y}px`}
                backgroundColor={'white'}
                borderRadius={'8px'}
                boxShadow={'0px 6px 34px 0px #00417933'}
                justifyContent={'flex-start'}
                padding={'20px'}
                zIndex={15}
              >
                {avatarTooltipJSX}
              </VStack>
            )}
            <Flex
              width={'100%'}
              height={'100%'}
              position={'absolute'}
              zIndex={10}
              onMouseMove={
                !!avatarTooltipJSX
                  ? ({ nativeEvent }) => {
                      setAvatarTooltipPosition({
                        x:
                          nativeEvent.offsetX < AVATAR_SIZE / 2
                            ? -AVATAR_SIZE * 1.5 - CIRCLE_DIAMETER_PADDING + nativeEvent.offsetX / 2
                            : AVATAR_SIZE - CIRCLE_DIAMETER_PADDING + nativeEvent.offsetX / 2,
                        y: nativeEvent.offsetY,
                      });
                    }
                  : undefined
              }
              onMouseOut={!!avatarTooltipJSX ? () => setAvatarTooltipPosition(null) : undefined}
            ></Flex>
            <CircleFillOutWithText
              circleDiameter={showCenteredTextOverlay ? AVATAR_SIZE : AVATAR_SIZE + CIRCLE_DIAMETER_PADDING}
              boxShadow={showCenteredTextOverlay ? undefined : ''}
              svgId={ID_COMBINED_WELLNESS_SCORE}
              thickness={10}
              percentageFilled={totalScoreX10 ?? combinedWellnessScore}
              percentageTextStyle={{
                display: showCenteredTextOverlay ? 'initial' : 'none',
                color: '#FFF',
                fontWeight: 700,
                fontSize: 30,
              }}
              centeredText={showCenteredTextOverlay ? 'Combined Wellness Score' : ''}
              circleColor={getColorFromPercentage(totalScoreX10 ?? combinedWellnessScore)}
              backgroundTransparent
            />
          </Flex>
        </Center>
        <Center position={'absolute'} width={'100%'} top={5}>
          <WellnessStateCircleChunk
            title={wellnessDataSorted[0].domainName} // "Mental"
            svgId={`${wellnessDataSorted[0].domainName}-svg`}
            percentageFilled={wellnessDataSorted[0].value} // {wellnessData.mental ?? 0}
          />
        </Center>
        <Center
          position={'absolute'}
          width={OUTER_CIRCLE_SIZE - 10}
          top={OUTER_CIRCLE_SIZE / 4.6}
          justifyContent={'space-between'}
        >
          <WellnessStateCircleChunk
            title={wellnessDataSorted[6].domainName}
            svgId={`${wellnessDataSorted[6].domainName}-svg`}
            percentageFilled={wellnessDataSorted[6].value}
            reversed
          />
          <WellnessStateCircleChunk
            title={wellnessDataSorted[1].domainName}
            svgId={`${wellnessDataSorted[1].domainName}-svg`}
            percentageFilled={wellnessDataSorted[1].value}
          />
        </Center>
        <Center
          position={'absolute'}
          width={OUTER_CIRCLE_SIZE + 40}
          marginLeft={'10px'}
          top={OUTER_CIRCLE_SIZE / 2.2}
          justifyContent={'space-between'}
          paddingLeft={'10px'}
        >
          <WellnessStateCircleChunk
            title={wellnessDataSorted[5].domainName}
            svgId={`${wellnessDataSorted[5].domainName}-svg`}
            percentageFilled={wellnessDataSorted[5].value}
            reversed
          />
          <WellnessStateCircleChunk
            title={wellnessDataSorted[2].domainName}
            svgId={`${wellnessDataSorted[2].domainName}-svg`}
            percentageFilled={wellnessDataSorted[2].value}
          />
        </Center>
        <Center
          position={'absolute'}
          width={OUTER_CIRCLE_SIZE / 1.4}
          top={OUTER_CIRCLE_SIZE - 280}
          justifyContent={'space-between'}
          paddingLeft={'10px'}
        >
          <WellnessStateCircleChunk
            title={wellnessDataSorted[4].domainName}
            svgId={`${wellnessDataSorted[4].domainName}-svg`}
            percentageFilled={wellnessDataSorted[4].value}
            reversed
          />
          <WellnessStateCircleChunk
            title={wellnessDataSorted[3].domainName}
            svgId={`${wellnessDataSorted[3].domainName}-svg`}
            percentageFilled={wellnessDataSorted[3].value}
          />
        </Center>
      </DrawnCirclesBackground>
    </Center>
  );
};

export default WellnessStateCircle;
