import iconsPng from '@/assets/img/png/icons';
import CustomButton from '@/components/atoms/CustomButton';
import AppPageTemplate from '@/components/templates/AppPageTemplate';
import { EMAIL_REGEX } from '@/constants/stringVars';
import { requestCustomerSupportPost } from '@/services/api/requests/customer-support';
import { useAppStore } from '@/store/useAppStore';
import { TUserContactSupport } from '@/types/User.types';
import { Box, Center, Image, Input, InputGroup, InputRightElement, Stack, Text, VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { createFileRoute } from '@tanstack/react-router';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

const schema = yup
  .object({
    email: yup.string().matches(EMAIL_REGEX, 'Please enter valid email address.').required('This field is required.'),
    message: yup.string().required('Message is required.'),
  })
  .required();

const ContactSupport = () => {
  const { user } = useAppStore();

  const {
    register,
    formState: { isValid, errors },
    handleSubmit,
  } = useForm<TUserContactSupport>({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      email: user?.email || '',
      message: '',
    },
  });

  const [isPending, setIsPending] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const onFormSubmit = async (data: TUserContactSupport) => {
    setIsPending(true);
    try {
      await requestCustomerSupportPost(data);
      setIsPending(false);
      setIsSuccess(true);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <AppPageTemplate>
      {isSuccess ? (
        <>
          <Box maxW={{ md: '600px' }} marginTop={'60px'}>
            <Center marginBottom={'50px'}>
              <Image alt="ok-hand" src={iconsPng.okHand} width={100} height={100} />
            </Center>
            <Text variant={'loraSmallTitle'} marginBottom={'20px'}>
              Your message was sent successfully.
            </Text>
            <Text variant={'urbanistSemiBold'} color={'background.blueGray'} fontWeight={500}>
              You will be contacted soon to the email address you provided.
            </Text>
          </Box>
          {/* <CustomButton
            isTransparent
            label="Send another Message"
            borderHoverColor="transparent"
            style={{ marginTop: '30px' }}
            onClick={() => {
              setIsSuccess(false);
              reset({ email: user?.email, message: '' });
            }}
          /> */}
        </>
      ) : (
        <>
          <Text variant={'loraSmallTitle'}>Contact MyOmnia Support</Text>
          <Box maxW={{ md: '600px' }} marginTop={'30px'}>
            <Text variant={'urbanistMedium'} color={'text.mediumGray'} textAlign={'center'}>
              If you have any questions or suggestions related to how MyOmnia works please send us an email via form
              below and we will reply to you as soon as possible.
            </Text>
          </Box>
          <Box minW={{ md: '295px' }} marginTop={'50px'}>
            <form style={{ width: '100%' }} onSubmit={handleSubmit(onFormSubmit)}>
              <Stack>
                {user?.email ? (
                  <VStack
                    gap={0}
                    backgroundColor={'white'}
                    borderRadius={'8px'}
                    paddingX={'16px'}
                    cursor={'not-allowed'}
                    marginBottom={'10px'}
                  >
                    <Text
                      color={'text.mediumGray'}
                      fontWeight={800}
                      lineHeight={'12px'}
                      fontSize={'10px'}
                      paddingTop={'12px'}
                      width={'100%'}
                      textAlign={'start'}
                    >
                      YOUR EMAIL ADDRESS
                    </Text>
                    <InputGroup>
                      <Input
                        {...register('email', { required: true })}
                        disabled={true} // disabling this because we are in the `user?.email` part of the ternary expression
                        _disabled={{ opacity: 1, cursor: 'not-allowed' }}
                        maxHeight={'22px'}
                        lineHeight={'18px'}
                        borderWidth={'0'}
                        fontSize={'14px'}
                        padding={'0px'}
                        marginBottom={'8px'}
                        fontWeight={600}
                        color={'text.mediumGray'}
                      />
                      <InputRightElement width="2rem" height={'100%'} marginTop={'-12px'}>
                        <Image alt="lock" src={iconsPng.lock} width={25} height={25} />
                      </InputRightElement>
                    </InputGroup>
                  </VStack>
                ) : (
                  <Stack>
                    <Input
                      {...register('email', { required: true })}
                      className="custom-input"
                      placeholder={'Email'}
                      _placeholder={{ fontWeight: '600', color: 'text.mediumGray' }}
                      noOfLines={5}
                    />
                    <Text variant={'error'} textAlign={'left'}>
                      {errors.email?.message ?? ''}
                    </Text>
                  </Stack>
                )}
                <Stack>
                  <Input
                    {...register('message', { required: true })}
                    className="custom-input"
                    as={'textarea'}
                    placeholder={'Message'}
                    style={{ minHeight: '170px', maxHeight: '550px' }}
                    _placeholder={{ fontWeight: '600', color: 'text.mediumGray' }}
                  />
                  <Text variant={'error'} textAlign={'left'}>
                    {errors.message?.message ?? ''}
                  </Text>
                </Stack>
                <CustomButton
                  label="Send Message"
                  backgroundColor="secondary.500"
                  labelColor="white"
                  isLoading={isPending}
                  disabled={!isValid}
                  buttonType="submit"
                  style={{ marginTop: '20px' }}
                />
              </Stack>
            </form>
          </Box>
        </>
      )}
    </AppPageTemplate>
  );
};

export const Route = createFileRoute('/contact-support')({
  component: ContactSupport,
});
