import { WELLNESS_DATA_MULTIPLIER } from '@/constants';
import { api } from '@/services/api';
import { THealthDomainScores } from '@/types/Health.types';
import { TOrganizationDashboard } from '@/types/Organization.types';
import { queryOptions } from '@tanstack/react-query';

type TAverageScoresData = {
  mental: number;
  spiritual: number;
  vocational: number;
  fun: number;
  purpose: number;
  social: number;
  physical: number;
  totalScore?: number;
};

export const getAssessmentsResultLastScore = () =>
  queryOptions({
    queryKey: ['assessments-result-last-score'],
    queryFn: async (): Promise<TAverageScoresData> => {
      const res = await api.get('/v1/assessments/result/last');
      // TODO: If you think that be can return status 200 but "success": false, you can throw an error here
      const wellnessData = res.data.data;

      for (const key in wellnessData) {
        if (typeof wellnessData[key as keyof THealthDomainScores] == 'number' && key !== 'totalScore') {
          wellnessData[key as keyof THealthDomainScores] *= WELLNESS_DATA_MULTIPLIER;
        }
      }

      return wellnessData;
    },
  });

export const getAssessmentsResultAverageScoresOrganization = () =>
  queryOptions({
    queryKey: ['assessments-result-average-scores-organization'],
    queryFn: async (): Promise<TAverageScoresData> => {
      const res = await api.get('/v1/assessments/result/average-scores/organization');
      // TODO: If you think that be can return status 200 but "success": false, you can throw an error here
      const wellnessData = res.data.data;

      for (const key in wellnessData) {
        if (typeof wellnessData[key as keyof THealthDomainScores] == 'number') {
          wellnessData[key as keyof THealthDomainScores] *= WELLNESS_DATA_MULTIPLIER;
        }
      }

      return wellnessData;
    },
  });

export const getOrganizationDashboard = () =>
  queryOptions({
    queryKey: ['admin-organization-dashboard'],
    queryFn: async (): Promise<TOrganizationDashboard> => {
      const { data: response } = await api.get<{ data: TOrganizationDashboard }>('/v1/organization/my/dashboard');

      const feData = response.data;
      const healthDomainScores = feData.score;

      for (const key in healthDomainScores) {
        if (typeof healthDomainScores[key as keyof THealthDomainScores] == 'number' && key !== 'totalScore') {
          healthDomainScores[key as keyof THealthDomainScores] *= WELLNESS_DATA_MULTIPLIER;
        }
      }

      feData.score = healthDomainScores;

      return feData;
    },
  });
