import { Dispatch, useEffect, useMemo, useState } from 'react';

import * as d3 from 'd3';

import colorConsts from '@/theme/colors';
import { Center, Flex, HStack, Text, VStack } from '@chakra-ui/react';

export type WellnessItemType = 'work' | 'mental' | 'physical';

export type TChartDataItem = {
  name: string;
  value: number;
  itemType?: WellnessItemType;
  description?: string;
};

export type TChartColorItem = {
  name: string;
  value: string;
};

export type THoverElement = {
  x: number;
  y: number;
  text: string;
  value: number;
  color: string;
  description?: string;
} | null;

const CHART_SVG_WIDTH = 220;
const CHART_PADDING = 25;
const CHART_SHADOW_PADDING = 15;
const MAX_CHART_WIDTH = 500;
const CHART_PAD_ANGLE = 0.05;

const renderChart = (
  width = CHART_SVG_WIDTH,
  data: TChartDataItem[],
  colors: TChartColorItem[],
  hoverElementInfo: THoverElement,
  setHoverElementInfo: Dispatch<THoverElement>,
) => {
  const height = Math.min(width, MAX_CHART_WIDTH);
  const radius = Math.min(width, height) / 2;

  const arc = d3
    .arc<d3.PieArcDatum<TChartDataItem>>()
    .innerRadius(radius / 2)
    .outerRadius(radius - 1)
    .cornerRadius(10);

  const padAngle = data.find((element) => element.value === 100) ? 0 : CHART_PAD_ANGLE;

  const pie = d3
    .pie<TChartDataItem>()
    .sort(null)
    .value((d) => d.value)
    .padAngle(padAngle);

  const color = colors
    ? (itemName: string) => colors.find((color: TChartColorItem) => color.name === itemName)?.value
    : d3
        .scaleOrdinal()
        .domain(data.map((d) => d.name))
        .range(d3.quantize((t) => d3.interpolateSpectral(t * 0.8 + 0.1), data.length).reverse());

  const theChartElement = d3.select('#chart-container-id');
  theChartElement.selectChildren().remove(); // to make sure we always have just one chart rendered

  const chartSvg = theChartElement
    .append('svg')
    .attr('width', width + CHART_SHADOW_PADDING)
    .attr('height', height + CHART_SHADOW_PADDING)
    .attr('viewBox', [
      -(width + CHART_SHADOW_PADDING) / 2,
      -(height + CHART_SHADOW_PADDING) / 2,
      width + CHART_SHADOW_PADDING,
      height + CHART_SHADOW_PADDING,
    ]);

  let timeoutRef: ReturnType<typeof setTimeout>;

  chartSvg
    .append('g')
    .selectAll()
    .data(pie(data))
    .join('path')
    .attr('fill', (d) => color(d.data.name) as string)
    .attr('d', arc)
    .attr('filter', (d) =>
      d.data.name === hoverElementInfo?.text ? 'drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.25)' : '',
    )
    .on('mousemove', (e, d) => {
      clearTimeout(timeoutRef);

      setHoverElementInfo({
        text: d.data.name,
        value: d.data.value,
        x: e.offsetX < width / 2 ? -width + CHART_PADDING + e.offsetX / 2 : width - CHART_PADDING + e.offsetX / 2,
        y: e.offsetY,
        color: colors.find((color: TChartColorItem) => color.name === d.data.name)?.value ?? 'black',
        description: d.data.description,
      });
    })
    .on('mouseleave', () => {
      timeoutRef = setTimeout(() => {
        setHoverElementInfo(null);
      }, 300);
    })
    // .append('title') // this adds the small text hover tooltip after 2 seconds
    .text((d) => `${d.data.name}: ${d.data.value.toLocaleString()}%`);

  return chartSvg.node();
};

const DoughnutChart = ({
  chartSvgWidth = CHART_SVG_WIDTH,
  data,
  colors,
  hasLegend = true,
  hasTooltip = true,
  titleElement = null,
  centeredElement = null,
  footerElement = null,
}: {
  chartSvgWidth?: number;
  data: TChartDataItem[];
  colors?: TChartColorItem[];
  hasLegend?: boolean;
  hasTooltip?: boolean;
  titleElement?: JSX.Element | null;
  centeredElement?: JSX.Element | null;
  footerElement?: JSX.Element | null;
}) => {
  const [hoverElementInfo, setHoverElementInfo] = useState<THoverElement>(null);

  const finalColors = useMemo(() => {
    if (colors) return colors;

    let i = data.length;
    const generatedColors: TChartColorItem[] = [];
    const opacityStep = Math.ceil((1 / (data.length + 1)) * 255);

    while (i > 0) {
      generatedColors.push({
        name: data[data.length - i].name,
        value: `${colorConsts.primary[500]}${(opacityStep * i).toString(16)}`,
      });
      i--;
    }

    return generatedColors;
  }, [data, colors]);

  useEffect(() => {
    let i = data.length;
    const generatedColors: TChartColorItem[] = [];
    const opacityStep = Math.ceil((1 / (data.length + 1)) * 255);
    while (i > 0) {
      generatedColors.push({
        name: data[data.length - i].name,
        value: `${colorConsts.primary[500]}${(opacityStep * i).toString(16)}`,
      });
      i--;
    }

    renderChart(chartSvgWidth, data, finalColors, hoverElementInfo, hasTooltip ? setHoverElementInfo : () => {});
  }, [data, chartSvgWidth, finalColors, hoverElementInfo]);

  return (
    <VStack>
      {titleElement}

      {/* Chart Content (chart and overlayed elements) */}
      <VStack position={'relative'}>
        <HStack gap={'40px'}>
          <VStack
            background="white"
            borderRadius={'50%'}
            boxShadow={'0px 6px 34px 0px #00417933'}
            position={'relative'}
            onMouseLeave={() => setHoverElementInfo(null)} // a small hack to be sure we cleared the hover-tooltip element
          >
            {/* Overlayed elements */}
            <Center position={'absolute'} width={'100%'} height={'100%'} zIndex={0} top={0}>
              {centeredElement && (
                <Center width={'50%'} height={'50%'}>
                  {centeredElement}
                </Center>
              )}

              {hoverElementInfo && (
                <VStack
                  position={'absolute'}
                  left={`${hoverElementInfo.x}px`}
                  top={`${hoverElementInfo.y}px`}
                  width={'200px'}
                  backgroundColor={'white'}
                  borderRadius={'8px'}
                  boxShadow={'0px 6px 34px 0px #00417933'}
                  justifyContent={'flex-start'}
                  padding={'10px'}
                >
                  <Text color={hoverElementInfo.color} variant={'urbanistBold'} fontSize={'20px'} width={'100%'}>
                    {hoverElementInfo.value}%
                  </Text>
                  <Text variant={'urbanistSemiBold'} width={'100%'} textAlign={'start'}>
                    {hoverElementInfo.text}
                  </Text>
                  <Text variant={'urbanistExtraBold'} width={'100%'} color={'text.mediumGray'}>
                    {hoverElementInfo.description}
                  </Text>
                </VStack>
              )}
            </Center>

            {/* Chart */}
            <div style={{ padding: `${CHART_PADDING}px`, zIndex: 1 }} id="chart-container-id"></div>
          </VStack>

          {/* Legend Content */}
          {hasLegend && (
            <VStack gap={'20px'}>
              {data.map((item) => (
                <HStack key={item.name} width={'100%'}>
                  <Flex
                    borderRadius={'50%'}
                    width={'18px'}
                    height={'18px'}
                    background={finalColors?.find((color) => color.name === item.name)?.value}
                  ></Flex>
                  <Text
                    variant={'urbanistBold'}
                    fontSize={'16px'}
                    color={'text.blueGray'}
                    fontWeight={600}
                    marginLeft={'6px'}
                  >
                    {item.name}
                  </Text>
                  {/* {itemTypeToIcon(item.itemType)} */}
                </HStack>
              ))}
            </VStack>
          )}
        </HStack>
      </VStack>

      {/* Footer */}
      <Flex width={'100%'}>
        <VStack maxWidth={chartSvgWidth + CHART_PADDING * 2 + CHART_SHADOW_PADDING * 2}>{footerElement}</VStack>
      </Flex>
    </VStack>
  );
};

export default DoughnutChart;
